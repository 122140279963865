export default class VisualController {
    constructor() {
        this.selected = [];
        this.init();
    }

    init() {
        this.eventBind();
    }

    //イベント
    eventBind() {
        const that = this;

        //表示非表示
        $('.visual_controller__open').on('click', function () {
            if (!$('.visual_controller__list').hasClass('active')) {
                that.openVisualController();
            } else {
                that.closeVisualController();
            }
        });

        //状態のチェック
        $('.visual_controller__item').on('click', function () {
            that.checkVisualController($(this));
        });

        //ビジュアルの切り替え
        $('.visual_controller__switch').on('click', function () {
            if ($(this).hasClass('mv')) {
                that.switchMainVisual($(this));
            } else if ($(this).hasClass('pair')) {
                that.switchPairVisual($(this));
            }
        });
    }


    //切り替わりののアニメーション
    switch_anim() {
        //TweenMax.fromTo('.serif_wrapper', 2, { width: '0%' }, { opacity: 1, width: '100%', ease: Circ.easeInOut, delay: 0 });
        TweenMax.staggerFromTo('.js-mainvisual', 0.8, { opacity: 0, x: 100 }, { opacity: 1, x: 0, ease: Circ.easeOut, delay: 0.5 }, 0.4);
    }


    //コントローラの表示
    openVisualController() {
        //セレクターが開いた際は、カレント状態だけ残して、内部の配列は初期化する
        this.selected = [];
        $('.visual_controller__open').addClass('active');
        $('.visual_controller__list').addClass('active');
    }

    //コントローラの非表示
    closeVisualController() {
        $('.visual_controller__open').removeClass('active');
        $('.visual_controller__list').removeClass('active');
    }

    //キャラ切り替え_監視
    checkVisualController($eventObs) {
        //アクティブなボタンをクリックしたとき以外に配列を更新
        if (!$eventObs.hasClass('selected')) {
            const val = $eventObs.attr('data-characterVal');
            this.selected.push(val);

            if (this.selected.length == 1) {
                //最初の一人の時は、選択をすべて外す
                $('.visual_controller__item').removeClass('selected');
            } else if (this.selected.length == 2) {
                //ビジュアル変更
                this.changeVisual();

                //コントローラーを非表示
                this.closeVisualController();

                //メインビジュアルを表示中のときはペアビジュアルに切り替え
                if ($('.mainvisual__ph').hasClass('is-active')) {
                    this.switchPairVisual($('.visual_controller__switch'));
                }
            }

            //アイコンのアクティブ化
            $eventObs.toggleClass('selected');
        }
    }


    //キャラ切り替え本体
    changeVisual() {

        //配列をアルファベット順にソート a,a .. a,fのような順に変換
        this.selected.sort(function (a, b) {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        });

        //ch_で始まるクラスを削除
        const target = this.selected.join('');

        $('.js-mainvisual,.js-mainvisual_serif,.js-theme').removeClass(function (index, className) {
            return (className.match(/\bch_\S+/g) || []).join(' ');
        });

        //付け替え
        $('.js-mainvisual,.js-mainvisual_serif,.js-theme').addClass('ch_' + target);
        this.switch_anim();
    }


    //メインビジュアルに切り替え
    switchMainVisual($eventObs) {
        this.selected = [];
        $eventObs.addClass('pair');
        $eventObs.removeClass('mv');
        $('.mainvisual__ph').addClass('is-active');
        $('.chara_wrapper').removeClass('is-active');
    }

    //ペアビジュアルに切り替え
    switchPairVisual($eventObs) {
        $eventObs.addClass('mv');
        $eventObs.removeClass('pair');
        $('.chara_wrapper').addClass('is-active');
        $('.mainvisual__ph').removeClass('is-active');
    }


}