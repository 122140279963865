export default class Top {
    constructor() {
        this.init();
    }

    init() {
        //this.setColorTheme();
        this.loader();
    }

    //色テーマのランダム化
    setColorTheme() {
        const rn = Math.floor(6 * Math.random()) + 1;
        $('body').addClass('color_theme0' + rn);
    }

    loader() {
        //const rn = Math.floor(3 * Math.random()) + 1;
        //$('.l-loader').addClass('ls' + rn);
        const tl = new TimelineMax();
        tl.set('.mainvisual__catch', { opacity: 0, y: 50 });
        tl.set('.mainvisual__link', { opacity: 0, scale:0.95, y: 50 });
        tl.set('.js-mainvisual', { opacity: 0, x: 200 });
        tl.set('.l-header', { opacity: 0 });
        tl.set('.visual_controller', { opacity: 0 });
        $('.visual_controller').addClass('active');

        $(window).on('load', function () {
            tl.to('.l-loader', 1, { opacity: 0, display: 'none' }, 1);
            tl.to('.mainvisual__catch', 0.5, { opacity: 1, y: 0 }, 2);
            tl.to('.mainvisual__link', 0.5, { opacity: 1, scale:1, y: 0 }, 3);
            tl.to('.l-header', 0.5, { opacity: 1 }, 2);
            tl.to('.visual_controller', 0.4, { opacity: 1 }, 2.5);
            tl.call(function(){
                $('.visual_controller').removeClass('active');
            } , null , null , 3.5);
            tl.staggerTo('.js-mainvisual', 1, { opacity: 1, x: 0, ease: Circ.easeOut }, 0.5, 2.5);
        });
    }

}